/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://i4zrpkrgurcr5dyrbwgsflcgga.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-brh5jb5dw5biboz6hzj243f6du",
    "aws_cognito_identity_pool_id": "ap-northeast-2:25d2818e-29af-41f4-840d-e591b72b5152",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_bF1Xc8Fmt",
    "aws_user_pools_web_client_id": "22r1f9jbvtcjlgr1gvoki1d768",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amp-hwadong-bucket-staging",
    "aws_content_delivery_bucket_region": "ap-northeast-2",
    "aws_content_delivery_url": "https://djb0u3cgci37l.cloudfront.net",
    "aws_user_files_s3_bucket": "amp-hwadong-storage-74a639ac74950-staging",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
